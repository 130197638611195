.checkbox-container {
	display: flex;
	gap: 7px;
	width: fit-content;
	cursor: pointer;

	.checkbox {
		position: relative;
		min-width: 30px;
		min-height: 30px;
		width: 30px;
		height: 30px;
		border-radius: 3.5px;
		border: 1px solid #a5a5a5;

		@media only screen and (max-width: @screen-mobile-max) {
			min-width: 20px;
			min-height: 20px;
			width: 20px;
			height: 20px;
		}

		&.checked {
			&::after {
				content: '';
				position: absolute;
				top: 4px;
				left: 4px;
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				background: #0066ff;
				border-radius: 2px;
			}

			&.use-icon-check {
				&::after {
					background-image: url('/icons/icon-check-blue.svg');
					background-size: contain;
					background-position: center;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: unset;
				}
			}
		}
	}

	.label {
		display: flex;
		align-items: center;
		color: @white;
		font-size: 20px;
		font-weight: 700;

		@media only screen and (max-width: @screen-mobile-max) {
			font-size: 13px;
		}
	}
}


@import '/workspace/src/styles/variables.less';